import generateRandomId from 'helpers/generateRandomId';

// 40mb
export const MAX_CSV_FILE_SIZE = 41943040;

export const APPBAR_HEIGHT = 84;

export const CUSTOMER_DATA = {
  _id: generateRandomId(),
  status: 'draft',
  vendorClientId: '',
  ccEmails: [''],
  deliveryDays: [
    {
      name: 'monday',
      enabled: false,
    },
    {
      name: 'tuesday',
      enabled: false,
    },
    {
      name: 'wednesday',
      enabled: false,
    },
    {
      name: 'thursday',
      enabled: false,
    },
    {
      name: 'friday',
      enabled: false,
    },
    {
      name: 'saturday',
      enabled: false,
    },
    {
      name: 'sunday',
      enabled: false,
    },
  ],
  contacts: [
    {
      name: '',
      email: '',
      phone: '',
      additionalPhones: [],
      _id: generateRandomId(),
      inviteLink: null,
      registrationCode: null,
      status: null,
      userMeta: null,
      isNewContact: true,
    },
  ],
  contactData: {
    businessName: '',
    street: '',
    houseNumber: '',
    zip: '',
    city: '',
    country: 'DE',
  },
  customerGroups: [],
  lists: [],
};

export const VENDOR_DATA = () => ({
  _id: generateRandomId(),
  ccEmails: [''],
  deliveryDays: [
    {
      name: 'monday',
      enabled: false,
    },
    {
      name: 'tuesday',
      enabled: false,
    },
    {
      name: 'wednesday',
      enabled: false,
    },
    {
      name: 'thursday',
      enabled: false,
    },
    {
      name: 'friday',
      enabled: false,
    },
    {
      name: 'saturday',
      enabled: false,
    },
    {
      name: 'sunday',
      enabled: false,
    },
  ],
  contacts: [
    {
      name: '',
      email: '',
      phone: '',
      additionalPhones: [],
      password: '',
      _id: generateRandomId(),
    },
  ],
  contactData: {
    businessName: '',
    street: '',
    houseNumber: '',
    zip: '',
    city: '',
    country: 'DE',
  },
  isPremium: false,
  branding: {
    primaryColor: null,
    secondaryColor: null,
    fontColor: null,
  },
});

export const PRODUCT_DATA = () => ({
  _id: generateRandomId(),
  name: '',
  vendorSpecificId: '',
  image: '',
  description: '',
  categories: [],
  variants: [
    {
      _id: generateRandomId(),
      baseUnit: '',
      packaging: '',
      price: null,
      unitsInPackaging: null,
    },
  ],
});

export const NEW_CONTACT = () => ({
  name: '',
  email: '',
  phone: '',
  additionalPhones: [],
  _id: generateRandomId(),
  inviteLink: null,
  registrationCode: null,
  status: null,
  userMeta: null,
  isNewContact: true,
});

export const NEW_VENDOR_CONTACT = () => ({
  name: '',
  email: '',
  phone: '',
  additionalPhones: [],
  _id: generateRandomId(),
  isNewContact: true,
  password: '',
});

export const CUSTOMER_GROUPS = [
  'cafe',
  'restaurant',
  'iceCreamParlor',
  'canteen',
  'pub',
  'hotel',
  'caterer',
  'snack',
  'other',
];

export const NOTIFICATION_STATUS = {
  SUCCESS: 'SUCCESS',
  ALERT: 'ALERT',
  WARNING: 'WARNING',
};

export const DELIVERY_DAYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const ORDER_STATUS = {
  PENDING: 'pending',
  DRAFT: 'draft',
  DELIVERED: 'receivedOk',
  ACCEPTED: 'accept',
  CANCELED: 'reject',
  PROBLEM: 'receivedFaulty',
};

export const DATE_STATUS = {
  INACTIVE: 'INACTIVE',
  OUT_OF_DATE: 'OUT_OF_DATE',
  ACTIVE: 'ACTIVE',
  UPCOMING: 'UPCOMING',
};

export const WEEK_DAYS_WITH_INDEXES = [
  {
    title: 'sunday',
    key: 0,
  },
  {
    title: 'monday',
    key: 1,
  },
  {
    title: 'tuesday',
    key: 2,
  },
  {
    title: 'wednesday',
    key: 3,
  },
  {
    title: 'thursday',
    key: 4,
  },
  {
    title: 'friday',
    key: 5,
  },
  {
    title: 'saturday',
    key: 6,
  },
];

export const ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100];

export const TABLE_SKELETON_TYPES = {
  CUSTOMERS: 'CUSTOMERS',
  PRODUCTS: 'PRODUCTS',
  DEALS: 'DEALS',
  ORDERS: 'ORDERS',
  LISTS: 'LISTS',
  LIST: 'LIST',
  NEWSLETTER_RECIPIENTS: 'NEWSLETTER_RECIPIENTS',
};

export const USER_ROLES = ['ADMIN', 'MANAGER'];

export const NEW_SALES_REP = () => ({
  name: '',
  email: '',
  password: '',
});

export const PRELOADED_PRODUCT_TYPE_ICONS = [
  'https://storage.googleapis.com/chfslst-static-files/regular%20products.png',
  'https://storage.googleapis.com/chfslst-static-files/cut%20products.png',
  'https://storage.googleapis.com/chfslst-static-files/preorder%20products.png',
  'https://storage.googleapis.com/chfslst-static-files/long%20delivery%20products.png',
];

export const PRODUCT_TYPE_DELIVERY_DAYS = {
  enabled: false,
  days: {
    _1: {
      dayOfWeekIndex: 1,
      enabled: false,
      name: 'monday',
      weekParity: null,
    },
    _2: {
      dayOfWeekIndex: 2,
      enabled: false,
      name: 'tuesday',
      weekParity: null,
    },
    _3: {
      dayOfWeekIndex: 3,
      enabled: false,
      name: 'wednesday',
      weekParity: null,
    },
    _4: {
      dayOfWeekIndex: 4,
      enabled: false,
      name: 'thursday',
      weekParity: null,
    },
    _5: {
      dayOfWeekIndex: 5,
      enabled: false,
      name: 'friday',
      weekParity: null,
    },
    _6: {
      dayOfWeekIndex: 6,
      enabled: false,
      name: 'saturday',
      weekParity: null,
    },
    _0: {
      dayOfWeekIndex: 0,
      enabled: false,
      name: 'sunday',
      weekParity: null,
    },
  },
};

export const PRODUCT_TYPE_CUT_OFF_TIME = {
  enabled: false,
  generic: {
    hour: 23,
    minute: 59,
    leadTime: {
      days: 0,
    },
  },
  perDay: {
    _1: {
      enabled: false,
      dayOfWeekIndex: 0,
      hour: 23,
      minute: 59,
      leadTime: {
        days: 0,
      },
    },
    _2: {
      enabled: false,
      dayOfWeekIndex: 0,
      hour: 23,
      minute: 59,
      leadTime: {
        days: 0,
      },
    },
    _3: {
      enabled: false,
      dayOfWeekIndex: 0,
      hour: 23,
      minute: 59,
      leadTime: {
        days: 0,
      },
    },
    _4: {
      enabled: false,
      dayOfWeekIndex: 0,
      hour: 23,
      minute: 59,
      leadTime: {
        days: 0,
      },
    },
    _5: {
      enabled: false,
      dayOfWeekIndex: 0,
      hour: 23,
      minute: 59,
      leadTime: {
        days: 0,
      },
    },
    _6: {
      enabled: false,
      dayOfWeekIndex: 0,
      hour: 23,
      minute: 59,
      leadTime: {
        days: 0,
      },
    },
    _0: {
      enabled: false,
      dayOfWeekIndex: 0,
      hour: 23,
      minute: 59,
      leadTime: {
        days: 0,
      },
    },
  },
};

export const NEW_PRODUCT_TYPE = {
  _id: 'new',
  vendorId: '',
  readableId: '',
  name: {
    en: null,
    de: null,
    fr: null,
    es: null,
    ve: null,
  },
  subTitle: {
    en: null,
    de: null,
    fr: null,
    es: null,
    ve: null,
  },
  description: {
    en: null,
    de: null,
    fr: null,
    es: null,
    ve: null,
  },
  hideAtProduct: null,
  icon: null,
  cutOffTime: {
    ...PRODUCT_TYPE_CUT_OFF_TIME,
  },
  deliveryDays: {
    ...PRODUCT_TYPE_DELIVERY_DAYS,
  },
  pickup: {
    cutOffTime: {
      ...PRODUCT_TYPE_CUT_OFF_TIME,
    },
    deliveryDays: {
      ...PRODUCT_TYPE_DELIVERY_DAYS,
    },
  },
};

export const PRODUCT_TYPE_WEEK_DAYS = [
  {
    dayIndex: 1,
    dayKey: '_1',
    title: 'monday',
  },
  {
    dayIndex: 2,
    dayKey: '_2',
    title: 'tuesday',
  },
  {
    dayIndex: 3,
    dayKey: '_3',
    title: 'wednesday',
  },
  {
    dayIndex: 4,
    dayKey: '_4',
    title: 'thursday',
  },
  {
    dayIndex: 5,
    dayKey: '_5',
    title: 'friday',
  },
  {
    dayIndex: 6,
    dayKey: '_6',
    title: 'saturday',
  },
  {
    dayIndex: 0,
    dayKey: '_0',
    title: 'sunday',
  },
];

export const WEEK_DAYS = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];
export const CAMPAIGN_FREQUENCY = ['Daily', 'Weekly', 'Monthly', 'OneTime'];

export const CUSTOM_SELECTOR_CAMPAIGN_FREQUENCY = [
  'Daily',
  'Weekly',
  'Monthly',
];

export const PRODUCT_TYPE_DELIVERY_METHOD = {
  DEFAULT: 'default',
  PICKUP: 'pickup',
};

export const DEFAULT_PT_READABLE_ID = '__default__';

export const specialDaysTypes = {
  blockedDates: 'blockedDate',
  exceptionDates: 'exceptionDate',
};

export const pricingTypes = {
  baseUnit: 'baseUnit',
  packaging: 'packaging',
};

export const ORDERS_TABLE_SECTION = {
  orders: 'orders',
  ai: 'ai',
};

export const INCOMING_ORDER_STATUSES = {
  Accepted: 'accepted', // -> Accepted, both auto and manually
  PendingReview: 'pendingReview', // -> Needs review and Plausibility issue
  Received: 'received', // -> Received, not processed yet (first temp status after email is received)
  Draft: 'draft', // -> Draft (only used if created manually)
  Rejected: 'rejected', // -> Canceled
  Error: 'error', // -> Failed
};

// NOTE: key here is used for translation
export const INCOMING_ORDER_STATUSES_FOR_LEGEND = {
  autoAccepted: {
    key: 'autoAccepted', // automatically accepted (with check mark)
    color: '#4BAE4F',
  },
  accepted: {
    key: 'accepted', // manually accepted
    color: '#4BAE4F',
  },
  pendingReview: {
    key: 'pendingReview', // needs review
    color: '#FFA800',
  },
  received: {
    key: 'received', // needs review
    color: '#797A7C',
  },
  // draft: {
  //   key: 'draft', // hide for now and display once we can manually create orders
  //   color: '#FFA800',
  // },
  rejected: {
    key: 'rejected', // rejected or canceled
    color: '#FF3D00',
  },
  error: {
    key: 'error', // rejected or canceled
    color: '#FF3D00',
  },
};

export const DEFAULT_CURRENCY = 'EUR';
export const DEFAULT_COUNTRY = 'DE';

export const PRODUCTS_TABLE_SECTION = {
  products: 'products',
  attributes: 'attributes',
};

export const ASSIGN_ACTIONS = {
  ASSIGN: 'ASSIGN',
  UNASSIGN: 'UNASSIGN',
};

export const USER_MANAGEMENT_ERRORS = {
  1: 'support.notValidated',
  2: 'support.errorWrongEmail',
  3: 'support.errorUserExists',
  'Vendor not found': 'support.errors.Vendor not found',
  'Some accounts are not valid': 'support.errors.Some accounts are not valid',
  'Account already active': 'support.errors.Account already active',
  'Source customer not found': 'support.errors.Source customer not found',
  'Source customer is not active':
    'support.errors.Source customer is not active',
  'Target customer not found': 'support.errors.Target customer not found',
  "Customer doesn't have lists": "support.errors.Customer doesn't have lists",
  'Some connections are not valid':
    'support.errors.Some connections are not valid',
  'No valid emails provided': 'support.errors.No valid emails provided',
  'Some emails already in use': 'support.errors.Some emails already in use',
  'Some emails are not valid': 'support.errors.Some emails are not valid',
  'Connection is not active': 'support.errors.Connection is not active',
  'User not found': 'support.errors.User not found',
};

export const CUSTOMER_MANAGEMENT_ERRORS = {
  CUSTOMER_ID_ALREADY_EXISTS: 'customers.errors.Customer Id already exists',
};

export const VALIDATION_TYPES = {
  Connection: 'Connection',
  Account: 'Account',
  User: 'User',
};

export const VALIDATION_TYPES_ERRORS = {
  Connection: 'support.validationTypes.Connection',
  Account: 'support.validationTypes.Account',
  User: 'support.validationTypes.User',
  Vendor: 'support.validationTypes.Vendor',
};

export const INCOMING_ORDER_SORTING_OPTIONS = {
  BusinessName: 'businessName',
  VendorClientId: 'vendorClientId',
  Status: 'status',
  CreatedAt: 'createdAt',
  ReceivedAt: 'receivedAt',
  DeliveryDate: 'deliveryDate',
};

export const INCOMING_ORDER_PRODUCTS_SORTING_OPTIONS = {
  None: 'none',
  Ingested: 'ingested',
  IngestedReversed: 'ingestedReversed',
  Name: 'name',
  NameReversed: 'nameReversed',
  VendorSpecificId: 'vendorSpecificId',
  VendorSpecificIdReversed: 'vendorSpecificIdReversed',
  VendorSpecificIdNumeric: 'vendorSpecificIdNumeric',
  VendorSpecificIdNumericReversed: 'vendorSpecificIdNumericReversed',
  Categories: 'categories',
  CategoriesReversed: 'categoriesReversed',
  Custom: 'custom',
  CustomReversed: 'customReversed',
};

export const INCOMING_ORDER_CONFIRMATION_STATUS = {
  MANUALLY_ACCEPTED: 'manuallyAccepted',
};

export const INCOMING_ORDER_FILTERS_AND_SORTING_KEY =
  'incomingOrderFiltersAndSorting';

export const INCOMING_ORDERS_DEFAULT_FILTERS_AND_SORTING = {
  filters: {
    status: [],
    customerNumber: [],
    unassignedCustomers: false,
  },
  sorting: {
    sortBy: INCOMING_ORDER_SORTING_OPTIONS.CreatedAt,
    sortOrder: -1,
  },
};

export const COUNTRY_CODES = ['DE', 'UA', 'CH', 'US'];

export const CURRENCIES = ['USD', 'EUR', 'GBP', 'INR', 'CHF'];

export const SORTING_ORDER = [
  'none',
  'ingested',
  'ingestedReversed',
  'name',
  'nameReversed',
  'vendorSpecificId',
  'vendorSpecificIdReversed',
  'vendorSpecificIdNumeric',
  'vendorSpecificIdNumericReversed',
  'categories',
  'categoriesReversed',
  'custom',
  'customReversed',
];

export const SUPPORTED_LANGUAGES = {
  en: 'English',
  de: 'Deutsch',
};
