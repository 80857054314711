import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as AcceptedIcon } from 'assets/accepted.svg';
// import { ReactComponent as AcceptedIcon } from 'assets/accepted.svg';
import TimelapseRoundedIcon from '@material-ui/icons/TimelapseRounded';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';

export default function StatusIconReducer({ statusKey }) {
  switch (statusKey) {
    case 'autoAccepted':
      return AcceptedIcon.render();
    case 'received':
      return (
        <TimelapseRoundedIcon
          style={{
            height: 20,
            width: 20,
            display: 'flex',
            justifySelf: 'center',
            color: 'white',
          }}
        />
      );
    case 'error':
      return (
        <ErrorOutlineRoundedIcon
          style={{
            height: 20,
            width: 20,
            display: 'flex',
            justifySelf: 'center',
            color: 'white',
          }}
        />
      );
    default:
      return null;
  }
}

StatusIconReducer.propTypes = {
  statusKey: PropTypes.string.isRequired,
};
