import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { makeStyles, withStyles } from '@material-ui/styles';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import MuiButton from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Slide from '@material-ui/core/Slide';

import { NOTIFICATION_STATUS } from 'helpers/constants';
import { useMutation, useQuery } from '@apollo/client';
import { GET_INCOMING_ORDER_FILTER_OPTIONS } from 'graphql/query/incomingOrders';
import { SET_NOTIFICATION } from 'graphql/mutation/user';
import parseValidationErrors from 'helpers/parseValidationErrors';
import Loader from 'components/shared/Loader';
import IOSSwitch from 'components/shared/IOSSwitch';
import { isEqual } from 'lodash';

const Button = withStyles(({ spacing }) => ({
  root: {
    height: 40,
    borderRadius: 40,
    marginRight: spacing(2),
  },
}))(MuiButton);

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const FILTER_STATUSES = [
  // 'autoAccepted', add this later with all handling support on BE
  'accepted',
  'pendingReview',
  'received',
  'draft',
  'rejected',
  'error',
];

const DEFAULT_FILTERS = {
  status: [],
  customerNumber: [],
  unassignedCustomers: false,
};

const useStyles = makeStyles(({ spacing }) => ({
  dialogPaper: {
    width: 800,
    minHeight: 250,
  },
  formControl: {
    margin: spacing(1),
    width: 250,
    maxWidth: 250,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  unassignedCustomersFormControl: {
    marginLeft: 0,
    marginTop: spacing(1.5),
  },
}));

function IncomingOrdersFilters({
  isOpen,
  onClose,
  activeFilters,
  getPersistedFilters,
  handleApplyFilters,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [filters, setFilters] = useState(activeFilters || DEFAULT_FILTERS);

  const [setNotification] = useMutation(SET_NOTIFICATION);
  const { loading: isFiltersLoading, data } = useQuery(
    GET_INCOMING_ORDER_FILTER_OPTIONS,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
      onError: error => {
        console.error(error.message);
        setNotification({
          variables: {
            timeout: 4000,
            message:
              parseValidationErrors(error) || t('common.something wrong'),
            type: NOTIFICATION_STATUS.ALERT,
            isOpen: true,
          },
        });
      },
    }
  );

  useEffect(() => {
    if (isOpen) {
      const initPersistedFilters = getPersistedFilters();
      setFilters(
        initPersistedFilters?.filters || activeFilters || DEFAULT_FILTERS
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const availableFilters =
    data?.getIncomingOrderFilterOptions || DEFAULT_FILTERS;

  const handleChangeFilterStatus = event => {
    setFilters(prevFilters => ({
      ...prevFilters,
      status: event.target.value,
    }));
  };

  const handleChangeFilterCustomers = event => {
    setFilters(prevFilters => ({
      ...prevFilters,
      customerNumber: event.target.value,
    }));
  };

  const handleChangeShowUnassignedCustomers = event => {
    setFilters(prevFilters => ({
      ...prevFilters,
      unassignedCustomers: event.target.checked,
    }));
  };

  const handleClearFilters = () => {
    handleApplyFilters(DEFAULT_FILTERS);
  };

  const handleApplySelectedFilters = () => {
    if (isEqual(filters, activeFilters)) {
      onClose();
    } else {
      handleApplyFilters(filters);
    }
  };

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      classes={{
        paper: classes.dialogPaper,
      }}
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {t('aiOrders.filter')}
      </DialogTitle>
      <DialogContent>
        {isFiltersLoading ? (
          <Loader styles={{ height: '100%' }} />
        ) : (
          <Grid container>
            <FormControl className={classes.formControl}>
              <InputLabel shrink id="filter-status-selector">
                {t('aiOrders.status')}
              </InputLabel>
              <Select
                labelId="filter-status-selector"
                id="status-selector"
                multiple
                value={filters.status}
                placeholder={t('common.all')}
                displayEmpty
                onChange={handleChangeFilterStatus}
                input={<Input id="select-multiple-chip" />}
                renderValue={selected => {
                  if (!selected.length) {
                    return <em>{t('common.all')}</em>;
                  } else {
                    return (
                      <div className={classes.chips}>
                        {selected.map(value => (
                          <Chip
                            key={value}
                            label={t(`aiOrders.statuses.${value}`)}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    );
                  }
                }}
                MenuProps={MenuProps}
              >
                {FILTER_STATUSES.map(status => (
                  <MenuItem key={status} value={status}>
                    {t(`aiOrders.statuses.${status}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {availableFilters?.customers?.length > 0 && (
              <FormControl className={classes.formControl}>
                <InputLabel shrink id="filter-customer-number-selector">
                  {t('aiOrders.customer number')}
                </InputLabel>
                <Select
                  labelId="filter-customer-number-selector"
                  id="customer-number-selector"
                  multiple
                  value={filters.customerNumber}
                  displayEmpty
                  onChange={handleChangeFilterCustomers}
                  renderValue={customers => {
                    if (!customers.length) {
                      return <em>{t('common.all')}</em>;
                    } else {
                      return (
                        <div className={classes.chips}>
                          {customers.map(value => (
                            <Chip
                              key={value}
                              label={value}
                              className={classes.chip}
                            />
                          ))}
                        </div>
                      );
                    }
                  }}
                  MenuProps={MenuProps}
                >
                  {availableFilters.customers?.map(
                    ({ vendorClientId, contactData }) => {
                      const customerName = contactData?.businessName || '';
                      return (
                        <MenuItem key={vendorClientId} value={vendorClientId}>
                          {`${vendorClientId} | ${customerName}`}
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              </FormControl>
            )}
            <Grid container>
              <FormControlLabel
                className={classes.unassignedCustomersFormControl}
                control={
                  <IOSSwitch
                    checked={filters.unassignedCustomers}
                    onChange={handleChangeShowUnassignedCustomers}
                    name="unassignedCustomers"
                  />
                }
                label={t('aiOrders.filterTitle.unassignedCustomers')}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isFiltersLoading}
          onClick={handleClearFilters}
          color="secondary"
        >
          {t('common.clear')}
        </Button>
        <Button disabled={isFiltersLoading} onClick={onClose} color="secondary">
          {t('common.cancel')}
        </Button>
        <Button
          disabled={isFiltersLoading}
          onClick={handleApplySelectedFilters}
          variant="contained"
          color="primary"
        >
          {t('common.apply')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

IncomingOrdersFilters.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  activeFilters: PropTypes.object.isRequired,
  getPersistedFilters: PropTypes.func.isRequired,
  handleApplyFilters: PropTypes.func.isRequired,
};

export default IncomingOrdersFilters;
