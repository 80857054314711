/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { SEARCH_VENDOR_PRODUCTS } from 'graphql/query/products';
import { activeAccountIdVar } from 'graphql/apolloReactiveVariables';
import { useLazyQuery } from '@apollo/client';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';

import StyledInput from 'components/shared/Inputs/StyledInput';
import useDebounce from 'helpers/useDebounce';

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  popoverPaper: props => ({
    border: palette.border.lightGreyThinDark,
    padding: spacing(0, 1.5, 1.5),
    borderRadius: 8,
    marginTop: spacing(1.5),
    boxSizing: 'border-box',
    width: props.popoverWidth,
    minWidth: 250,
    maxHeight: 350,
  }),
  searchPaperContainer: {
    scrollbarColor: palette.primary.main,
  },
  searchInputContainer: {
    position: 'sticky',
    top: 0,
    background: palette.background.paper,
    zIndex: 1000,
    paddingTop: spacing(1.5),
  },
  listItemRoot: {
    margin: spacing(0.5, 0),
    '&:hover': {
      background: 'rgba(255, 81, 0, 0.08)',
      borderRadius: 4,
    },
  },
  selectedProduct: {
    background: 'rgba(255, 81, 0, 0.08)',
    borderRadius: 4,
    justifyContent: 'space-between',
  },
  productTitle: {
    fontWeight: `${typography.fontWeightBold} !important`,
  },
  productVendorId: {
    ...typography.body2,
    color: palette.text.secondary,
  },
  listContainer: {
    width: '100%',
  },
  noProductSearchResults: {
    padding: spacing(1),
  },
}));

const ProductSearchPopover = ({
  anchorEl,
  product,
  fromEmailConverter,
  handleClose,
  handleSetProduct,
}) => {
  const { t } = useTranslation();
  const classes = useStyles({ popoverWidth: anchorEl?.offsetWidth || 350 });

  const searchInputRef = useRef(null);

  const vendorId = activeAccountIdVar();
  console.log('SEARCH_VENDOR_ID', vendorId);

  const debounceRequest = useDebounce(500);

  const [searchInput, setSearchInput] = useState('');
  const [hasSearchResults, setSearchResultsAvailability] = useState(true);

  const [getSearchProducts, { data: searchData }] = useLazyQuery(
    SEARCH_VENDOR_PRODUCTS,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      onCompleted: searchResponse => {
        const productsResults =
          searchResponse?.searchAllVendorProducts?.products || [];
        if (!productsResults.length && hasSearchResults) {
          setSearchResultsAvailability(false);
        } else if (productsResults.length && !hasSearchResults) {
          setSearchResultsAvailability(true);
        }
      },
    }
  );

  const searchResults = searchData?.searchAllVendorProducts?.products || [];

  useEffect(() => {
    if (searchInput.length) {
      debounceRequest(() =>
        getSearchProducts({
          variables: {
            after: 1,
            searchPhrase: searchInput,
            vendorId,
            pageSize: 50,
            translateVariants: true,
            fromEmailConverter,
          },
        })
      );
    }
  }, [searchInput]);

  useEffect(() => {
    if (anchorEl) {
      const timer = setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        searchInputRef?.current?.focus();
        clearTimeout(timer);
      }, 100);
    }
  }, [anchorEl]);

  const handleInputSearch = event => {
    setSearchInput(event.target.value);
  };

  const selectorIsOpen = Boolean(anchorEl);

  return (
    <Popover
      classes={{ paper: classes.popoverPaper }}
      open={selectorIsOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Grid
        className={classes.searchPaperContainer}
        container
        direction="column"
        wrap="nowrap"
      >
        <Grid container className={classes.searchInputContainer}>
          <StyledInput
            variant="outlined"
            value={searchInput}
            placeholder={t('products.search placeholder')}
            onChange={handleInputSearch}
            fullWidth
            inputRef={searchInputRef}
            startAdornment={
              <InputAdornment position="start">
                <SearchRoundedIcon />
              </InputAdornment>
            }
          />
          <List
            component="nav"
            aria-label="search product titles"
            color="primary"
            className={classes.listContainer}
            disablePadding
          >
            {product?._id && (
              <ListItem
                button
                className={classes.selectedProduct}
                classes={{
                  root: classes.listItemRoot,
                }}
                key={product._id}
              >
                <Typography
                  variant="body2"
                  color="inherit"
                  className={classes.productTitle}
                >
                  {product.name}
                  <span className={classes.productVendorId}>
                    {` | ${product.vendorSpecificId}`}
                  </span>
                </Typography>
                <DoneRoundedIcon color="primary" />
              </ListItem>
            )}
          </List>
        </Grid>

        <List
          component="nav"
          aria-label="search product titles"
          color="primary"
          className={classes.listContainer}
          disablePadding
        >
          {!hasSearchResults && (
            <Typography
              align="center"
              className={classes.noProductSearchResults}
              variant="body2"
              color="textSecondary"
            >
              {t('common.no results')}
            </Typography>
          )}
          {searchResults?.map(searchResultProduct => (
            <ListItem
              button
              classes={{
                root: classes.listItemRoot,
              }}
              key={searchResultProduct._id}
              onClick={() => handleSetProduct(searchResultProduct)}
            >
              <Typography
                variant="body2"
                color="inherit"
                className={classes.productTitle}
              >
                {searchResultProduct.name}
                <span className={classes.productVendorId}>
                  {` | ${searchResultProduct.vendorSpecificId}`}
                </span>
              </Typography>
            </ListItem>
          ))}
        </List>
      </Grid>
    </Popover>
  );
};

ProductSearchPopover.defaultProps = {
  anchorEl: null,
  fromEmailConverter: false,
  product: {},
};

ProductSearchPopover.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
  product: PropTypes.object,
  fromEmailConverter: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleSetProduct: PropTypes.func.isRequired,
};

export default ProductSearchPopover;
