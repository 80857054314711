import { makeVar } from '@apollo/client';
import { DEFAULT_COUNTRY, DEFAULT_CURRENCY } from 'helpers/constants';
// import persistReactiveVariables from 'helpers/persistReactiveVariables';

export const activeAccountIdVar = makeVar(null);

export const currencyVar = makeVar(DEFAULT_CURRENCY);

export const countryVar = makeVar(DEFAULT_COUNTRY);

export const isProductTypeDisplayingEnabledVar = makeVar(false);

export const isVendorChangingVar = makeVar(false);
