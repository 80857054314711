import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';

import { NOTIFICATION_STATUS } from 'helpers/constants';
import { useMutation, useQuery } from '@apollo/client';
import { SET_NOTIFICATION } from 'graphql/mutation/user';
import { GET_INCOMING_ORDERS_FOR_REVIEW } from 'graphql/query/incomingOrders';
import parseValidationErrors from 'helpers/parseValidationErrors';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(({ spacing, palette }) => ({
  startReviewButton: {
    height: 40,
    borderRadius: 40,
    marginLeft: spacing(1),
  },
  acceptedOrdersButton: {
    backgroundColor: '#4BAE4F',
    color: palette.text.inverted,
    cursor: 'default',
    '&:hover': {
      backgroundColor: '#4BAE4F',
    },
  },
}));

function IncomingOrderStartReview() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [setNotification] = useMutation(SET_NOTIFICATION);
  const { data, loading } = useQuery(GET_INCOMING_ORDERS_FOR_REVIEW, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onError: error => {
      console.error(error.message);
      setNotification({
        variables: {
          timeout: 4000,
          message: parseValidationErrors(error) || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    },
  });

  const incomingOrdersForReview = data?.getIncomingOrdersForReview || [];

  const handleStartReview = () => {
    history.push(
      `/orders/ai-order-review?orderId=${incomingOrdersForReview[0]}`,
      {
        incomingOrdersForReview,
        baseIncomingOrdersRoute: `${location.pathname}${location.search}`,
      }
    );
  };

  if (loading) {
    return (
      <Skeleton
        variant="rect"
        width={150}
        className={classes.startReviewButton}
      />
    );
  }

  return incomingOrdersForReview?.length > 0 ? (
    <Button
      className={classes.startReviewButton}
      variant="contained"
      color="primary"
      disabled={loading}
      onClick={handleStartReview}
    >
      {t('aiOrders.start reviewing')}
    </Button>
  ) : (
    <Button
      className={classNames(
        classes.startReviewButton,
        classes.acceptedOrdersButton
      )}
      aria-readonly
      disabled={loading}
      disableElevation
      disableFocusRipple
      disableRipple
      disableTouchRipple
      variant="contained"
    >
      {t('aiOrders.all orders accepted')}
    </Button>
  );
}

export default IncomingOrderStartReview;
