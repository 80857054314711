/* eslint-disable max-len */
import { gql } from '@apollo/client';
import { TRANSLATION_FRAGMENT, GROUP_FRAGMENT } from 'graphql/fragments';

export const GET_VENDOR_CUSTOMERS = gql`
  ${GROUP_FRAGMENT}
  query vendors(
    $pageSize: Int
    $after: Int
    $sortBy: CustomerSortingOption
    $sortOrder: Int
  ) {
    vendorCustomers(
      pageSize: $pageSize
      after: $after
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      totalPages
      page
      hasNextPage
      nextPage
      hasPrevPage
      prevPage
      totalResults
      customers {
        _id
        vendorClientId
        vendorId
        status
        ccEmails
        deliveryDays {
          name
          enabled
        }
        contacts {
          name
          email
          phone
          additionalPhones
          _id
          inviteLink
          registrationCode
          status
          userMeta {
            name
            email
          }
        }
        users {
          name
          email
        }
        contactData {
          businessName
          street
          houseNumber
          zip
          city
          country
        }
        createdAt
        lists {
          _id
          title
        }
        orders
        customerGroups {
          ...GroupFragment
        }
      }
    }
  }
`;

export const SEARCH_VENDOR_ACCOUNTS = gql`
  query searchAccountsPaginated(
    $searchPhrase: String!
    $vendorId: String!
    $pageSize: Int
    $after: Int
  ) {
    searchAccountsPaginated(
      searchPhrase: $searchPhrase
      vendorId: $vendorId
      pageSize: $pageSize
      after: $after
    ) {
      totalPages
      page
      hasNextPage
      nextPage
      hasPrevPage
      prevPage
      totalResults
      data {
        _id
        contactData {
          businessName
        }
      }
    }
  }
`;

export const SEARCH_VENDOR_CUSTOMERS = gql`
  ${GROUP_FRAGMENT}
  query vendors($searchPhrase: String!, $pageSize: Int, $after: Int) {
    searchVendorCustomers(
      searchPhrase: $searchPhrase
      pageSize: $pageSize
      after: $after
    ) {
      totalPages
      page
      hasNextPage
      nextPage
      hasPrevPage
      prevPage
      totalResults
      customers {
        _id
        vendorClientId
        vendorId
        status
        ccEmails
        deliveryDays {
          name
          enabled
        }
        contacts {
          name
          email
          phone
          additionalPhones
          _id
          registrationCode
          inviteLink
          status
          userMeta {
            name
            email
          }
        }
        users {
          name
          email
        }
        contactData {
          businessName
          street
          houseNumber
          zip
          city
          country
        }
        createdAt
        lists {
          _id
          title
        }
        orders
        customerGroups {
          ...GroupFragment
        }
        deliveryDays {
          enabled
          name
        }
      }
    }
  }
`;

export const VENDORS_WITH_FTP = gql`
  query vendors {
    vendorsWithFtp {
      _id
      contactData {
        businessName
      }
      apiIntegration {
        enabled
      }
      ftpIntegration {
        enabled
        vendorName
        ingestOrders
      }
      branding {
        primaryColor
      }
      jobs {
        _id
        vendorId
        jobType
        jobDetails {
          vendorName
        }
        repeatEvery
        runAtTime {
          minute
          hour
          day
        }
      }
    }
  }
`;

export const GET_VENDOR_ACCOUNT = gql`
  query vendorAccount {
    vendorAccount {
      _id
      isPremium
      ccEmails
      contacts {
        _id
        phone
        name
        additionalPhones
        email
      }
      contactData {
        businessName
        street
        houseNumber
        zip
        city
        country
        image
      }
      users {
        userId
        role
        permissions
        status
      }
      branding {
        primaryColor
        secondaryColor
        fontColor
      }
      createdAt
      updatedAt
      deliveryDays {
        name
        enabled
      }
      hasPickupOptions
      preferences {
        aiOrderSettings {
          enabled
          inboxEmail
          notificationEmail
          notificationEmailCC
        }
        defaultProductImage
        twilio {
          enabled
        }
        mondu {
          vIBAN
        }
        newsletter {
          enabled
          fromName
          fromEmail
          staticInfo {
            workingHours
            workingHoursCaption
            phone
            email
            disclaimer
          }
        }
      }
    }
  }
`;

export const GET_ALL_VENDORS_LIST = gql`
  query Vendors {
    vendors {
      _id
      contactData {
        businessName
        image
      }
      preferences {
        disableCatalogSearch
        countryCode
        currency
        salesRepsEnabled
        productSorting
        mondu {
          enabled
        }
        twilio {
          enabled
        }
        splitOrders
        customProductSorting
        algolia {
          enabled
          version
          appId
        }
        limitCatalogSearchBy {
          collection
          field
        }
        cartWeightLimits {
          enabled
          min
        }
      }
      createdAt
      updatedAt
      ftpIntegration {
        enabled
      }
      apiIntegration {
        enabled
      }
      jobs {
        _id
        vendorId
        jobType
        jobDetails {
          vendorName
        }
        repeatEvery
        runAtTime {
          minute
          hour
          day
        }
        createdAt
        updatedAt
      }
      apiIntegration {
        enabled
      }
    }
  }
`;

export const GET_FULL_VENDOR_DATA = gql`
  ${TRANSLATION_FRAGMENT}
  query Vendors($vendorIds: [ID]) {
    vendors(vendorIds: $vendorIds) {
      _id
      isPremium
      contactData {
        businessName
        street
        houseNumber
        zip
        city
        image
        website
        country
        label
      }
      contacts {
        _id
        role
        phone
        additionalPhones
        email
        name
      }
      users {
        userId
        role
        permissions
        invitedAt
        status
      }
      ccEmails
      pricingModel {
        disabled
        decimalsAfterZero
        vendorName
        pricingType
        ruleOrder
        dealPriceField
        ignoreVariants
        _id
      }
      deliveryDays {
        name
        enabled
      }
      blockedDates {
        start
        end
      }
      preferences {
        locale {
          generic
          orderEmail
        }
        reverseDateRulesEnabled
        productSorting
        customProductSorting
        countryCode
        currency
        disableCatalogSearch
        salesRepsEnabled
        mondu {
          enabled
          vIBAN
        }
        twilio {
          enabled
          sid
          token
          smsFooter {
            ...TranslationFragment
          }
        }
        splitOrders
        algolia {
          enabled
          version
          appId
        }
        limitCatalogSearchBy {
          collection
          field
        }
        cartWeightLimits {
          enabled
          min
        }
        cartTotalLimits {
          enabled
          min
        }
        mainPackagingNames
        hideUnitsInPackagingLabel
        defaultProductImage
        enableProductTypeGroups
        expiringProducts {
          notifyEmails
          enabled
          expiringPackaging {
            createVariantOnImport
            removeSuffixOnExport
            suffix
          }
          displayMode {
            showInDeals
            showWithRegularProducts
          }
        }
        pdfCatalog {
          enabled
        }
        newsletter {
          enabled
          fromName
          fromEmail
          staticInfo {
            workingHours
            workingHoursCaption
            phone
            email
            disclaimer
          }
        }
      }
      branding {
        primaryColor
        secondaryColor
        fontColor
      }
      createdAt
      updatedAt
      ftpIntegration {
        enabled
        vendorName
        ingestOrders
        imageFolder
        pdfFolder
        emailForErrors
        disableErrorEmails
      }
      apiIntegration {
        enabled
        vendorName
      }
      orderProcessingOptions {
        ignoreMissingIds
        csvSeparator
        orderNumberPrefix
      }
      deliveryOptionsEnabled
      staticMessages {
        cart {
          enabled
          en
          de
        }
      }
      jobs {
        _id
        vendorId
        jobType
        jobDetails {
          vendorName
        }
        repeatEvery
        runAtTime {
          minute
          hour
          day
        }
        createdAt
        updatedAt
      }
      featureFlags {
        enablePaprikaDesign
        customerSuffixesEnabled
        isRichProductDescriptionsEnabled
        productMessagesEnabled
        homePage {
          enabled
          isPremium
          sections {
            name
            isVisible
          }
        }
      }
    }
  }
`;

export const GET_VENDOR_DELIVERY_OPTIONS = gql`
  query vendorDeliveryOptions($vendorId: ID!) {
    vendorDeliveryOptions(vendorId: $vendorId) {
      _id
      createdAt
      updatedAt
      isDefaultDelivery
      label
      vendorDeliveryId
      vendorId
      name
      translations {
        en
        de
        fr
        es
        ve
      }
      locations {
        _id
        vendorDeliveryLocationId
        name
        businessName
        street
        houseNumber
        zip
        city
        image
        country
        phone
        cutOffTime {
          deliveryOptionId
          enabled
          hour
          minute
          day
        }
        workingDays {
          _id
          name
          enabled
          hours {
            _id
            name
            enabled
            from {
              hour
              minute
            }
            to {
              hour
              minute
            }
          }
        }
        options {
          _id
          vendorDeliveryLocationOptionId
          name
        }
        defaultDeliveryDate
      }
    }
  }
`;
