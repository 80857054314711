export default function getParsedLocalStorageItem(key) {
  let value = localStorage.getItem(key);

  if (value !== null) {
    try {
      const parsed = JSON.parse(value);
      value = parsed;
    } catch {
      // It wasn't JSON ignore it
    }
  }

  return value;
}
