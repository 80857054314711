import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

const useStyles = makeStyles(({ spacing, palette, zIndex }) => ({
  aiOrderReviewProcessContainer: {
    position: 'absolute',
    top: -16,
    left: -22,
    width: 'calc(100% + 52px)',
    background: palette.background.paper,
    height: 40,
    zIndex: zIndex.appBar,
    borderTop: palette.border.grey,
    padding: spacing(0, 1),
  },
  orderSelectorButton: {
    margin: spacing(0, 1),
  },
}));

export default function OrderReviewProcess({
  reviewProcessOrderIds,
  currentReviewOrderIndex,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();

  const hasPrevOrder = currentReviewOrderIndex > 0;
  const hasNextOrder =
    currentReviewOrderIndex + 1 !== reviewProcessOrderIds?.length;

  const handleStopReviewing = () => {
    const { baseIncomingOrdersRoute } = location?.state || {};
    if (baseIncomingOrdersRoute) {
      history.push(baseIncomingOrdersRoute);
    } else {
      history.push('/orders/ai');
    }
  };

  const handleReviewPreviousOrder = () => {
    if (hasPrevOrder) {
      const previousOrderId =
        reviewProcessOrderIds[currentReviewOrderIndex - 1];
      history.push(`/orders/ai-order-review?orderId=${previousOrderId}`, {
        ...(location.state || {}),
      });
    }
  };

  const handleReviewNextOrder = () => {
    if (hasNextOrder) {
      const nextOrderId = reviewProcessOrderIds[currentReviewOrderIndex + 1];
      history.push(`/orders/ai-order-review?orderId=${nextOrderId}`, {
        ...(location.state || {}),
      });
    }
  };

  return (
    <Grid
      className={classes.aiOrderReviewProcessContainer}
      container
      alignItems="center"
      justifyContent="space-between"
    >
      <div style={{ width: 150 }} />
      <Grid container item xs={6} justifyContent="center" alignItems="center">
        {hasPrevOrder && (
          <IconButton
            aria-label="previous order"
            className={classes.orderSelectorButton}
            size="small"
            onClick={handleReviewPreviousOrder}
          >
            <ChevronLeftRoundedIcon fontSize="inherit" />
          </IconButton>
        )}
        <Typography variant="body2" color="textSecondary">
          {t('aiOrders.orders to review', {
            numOfOrder: currentReviewOrderIndex + 1,
            totalOrders: reviewProcessOrderIds.length,
          })}
        </Typography>
        {hasNextOrder && (
          <IconButton
            aria-label="next order"
            className={classes.orderSelectorButton}
            size="small"
            onClick={handleReviewNextOrder}
          >
            <ChevronRightRoundedIcon fontSize="inherit" />
          </IconButton>
        )}
      </Grid>
      <Button
        onClick={handleStopReviewing}
        endIcon={<CloseRoundedIcon fontSize="small" />}
      >
        {t('aiOrders.stop reviewing')}
      </Button>
    </Grid>
  );
}

OrderReviewProcess.propTypes = {
  reviewProcessOrderIds: PropTypes.array.isRequired,
  currentReviewOrderIndex: PropTypes.number.isRequired,
};
