/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { SET_NOTIFICATION } from 'graphql/mutation/user';
import { GET_ORDERS } from 'graphql/query/orders';
import { UPDATE_ORDER_STATUS } from 'graphql/mutation/orders';

import TableLayout from 'components/shared/TableLayout';
import OrdersTableRow from 'components/OrdersTable/OrdersTableRow';
import CancelOrderModal from 'components/OrdersTable/CancelOrderModal';
import {
  NOTIFICATION_STATUS,
  ORDER_STATUS,
  ROWS_PER_PAGE_OPTIONS,
  TABLE_SKELETON_TYPES,
} from 'helpers/constants';

import { makeStyles } from '@material-ui/styles';
import useMixPanel from 'helpers/useMixPanel';
import SwitchTablesActionButtons from 'components/OrdersTable/SwitchTablesActionButtons';
import { GET_VENDOR_ACCOUNT } from 'graphql/query/vendors';

const useStyles = makeStyles(theme => ({
  creationCustomerIdTitle: {
    color: theme.palette.secondary.darkGray,
    whiteSpace: 'nowrap'
  },
  downloadFileButton: {
    background: 'rgba(255, 81, 0, 0.15)',
    color: theme.palette.primary.main,
    minWidth: 0,
    width: 32,
    height: 32,
    boxShadow: '0px 0px 10px rgba(202, 202, 202, 0.25)',
    borderRadius: 6,
    padding: 0,
    margin: `0px ${theme.spacing(0.5)}px`,
  },
  deliveredIcon: {
    color: theme.palette.success.main
  },
  errorIcon: {
    color: theme.palette.error.main
  },
  statusText: {
    marginLeft: theme.spacing(0.5)
  },
  orderCustomerTitle: {
    width: 250
  },
}));

export default function Orders() {
  const { t } = useTranslation();
  const classes = useStyles();
  const mixPanel = useMixPanel();

  const history = useHistory();
  const location = useLocation();
  const queries = queryString.parse(location.search);
  const currentPath = location.pathname;

  const [state, setState] = React.useState({
    page: 0,
    rowsPerPage: 10,
    sortBy: 'orderedAt',
    sortOrder: -1
  });
  const [selectedOrder, setSelectedOrder] = React.useState(null);
  const [isOpenCancelOrderModal, setCancelOrderModal] = React.useState(false);
  const [showSearchResults, setShowSearchResult] = React.useState(false);

  const [setNotification] = useMutation(SET_NOTIFICATION);
  const [updateOrderStatus] = useMutation(UPDATE_ORDER_STATUS);

  const [getOrders, {
    loading: ordersLoading,
    error: ordersError,
    data,
  }] = useLazyQuery(
    GET_ORDERS,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true
    }
  );

  const { data: vendorAccount } = useQuery(GET_VENDOR_ACCOUNT, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const isAiOrdersEnabled = vendorAccount?.vendorAccount?.preferences?.aiOrderSettings?.enabled;

  const ordersData = data && data.vendorOrders;

  React.useEffect(() => {
    const pageParam = +queries.page;
    const rowsParam = +queries.rowsPerPage;
    const sortByParam = queries.sortBy;
    const sortOrderParam = +queries.sortOrder;

    getOrders({
      variables: {
        after: pageParam + 1 || state.page + 1,
        pageSize: rowsParam || state.rowsPerPage,
        sortBy: sortByParam || state.sortBy,
        sortOrder: sortOrderParam || state.sortOrder
      }
    });

    if (Object.keys(queries).length) {
      setState({
        ...state,
        page: pageParam || state.page,
        rowsPerPage: rowsParam || state.rowsPerPage,
        sortBy: sortByParam || state.sortBy,
        sortOrder: sortOrderParam || state.sortOrder
      });
    }
  }, []);

  React.useEffect(() => {
    const totalPages = ordersData?.totalPages ?? 0;
    const pageNumberExceeded = !!totalPages && (totalPages <= state.page);
    const rowsPerPageExceeded = !ROWS_PER_PAGE_OPTIONS.includes(state.rowsPerPage);

    if (ordersData && (pageNumberExceeded || rowsPerPageExceeded)) {
      history.replace({
        pathname: currentPath,
        search: queryString.stringify({
          ...queryString.parse(location.search),
          page: state.page !== 0 && pageNumberExceeded ? 0 : state.page,
          rowsPerPage: rowsPerPageExceeded ? 10 : state.rowsPerPage,
        })
      });
      window.location.reload();
    }
  }, [ordersData]);

  const handleChangePage = async (event, page, search) => {
    const searchPhrase = search || queries.search;
    if (ordersData && (ordersData.hasNextPage || page + 1 < ordersData.totalPages)) {
      try {
        await getOrders({
          variables: {
            searchPhrase,
            after: page + 1,
            pageSize: state.rowsPerPage,
            sortBy: state.sortBy,
            sortOrder: state.sortOrder
          }
        });
      } catch (error) {
        console.error(error.message);
      }
    }
    setState({ ...state, page });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({ ...queryString.parse(location.search), page })
    });
  };

  const handleChangeRowsPerPage = async (event) => {
    const rowsPerPage = +event.target.value;
    try {
      await getOrders({
        variables: {
          pageSize: rowsPerPage,
          after: 1,
          sortBy: state.sortBy,
          sortOrder: state.sortOrder
        }
      });
    } catch (error) {
      console.error(error.message);
    }
    setState({ ...state, rowsPerPage, page: 0 });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        rowsPerPage,
        page: 0
      })
    });
  };

  const handleSortRows = (sortLabel) => async () => {
    const isAsc = state.sortBy === sortLabel && state.sortOrder === 1;
    const sortOrder = isAsc ? -1 : 1;
    try {
      await getOrders({
        variables: {
          pageSize: state.rowsPerPage,
          after: 1,
          sortBy: sortLabel,
          sortOrder
        }
      });
    } catch (error) {
      console.error(error.message);
    }
    setState({
      ...state, sortOrder, sortBy: sortLabel, page: 0
    });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        rowsPerPage: state.rowsPerPage,
        page: 0,
        sortBy: sortLabel,
        sortOrder
      })
    });
  };

  const showCancelOrderModal = order => {
    setSelectedOrder(order);
    setCancelOrderModal(true);
  };

  const handleAcceptOrder = async (order) => {
    try {
      await updateOrderStatus({
        variables: {
          orderId: order._id,
          status: ORDER_STATUS.ACCEPTED
        },
        refetchQueries: [{ query: GET_ORDERS }]
      });
    } catch (error) {
      console.error(error.message);
    }
    mixPanel.track('Update Order Status', {
      orderId: order._id,
      internalOrderId: order.internalOrderId,
      status: ORDER_STATUS.ACCEPTED
    });
    setNotification({
      variables: {
        timeout: 4000,
        message: t('orders.acceptOrder', { orderId: order.internalOrderId }),
        type: NOTIFICATION_STATUS.SUCCESS,
        isOpen: true,
      },
    });
  };

  const messageOrder = order => {
    // TODO: message order
    setSelectedOrder(order);
    setNotification({
      variables: {
        timeout: 4000,
        message: t('orders.messageOrder', { orderId: order.internalOrderId }),
        type: NOTIFICATION_STATUS.SUCCESS,
        isOpen: true,
      },
    });
  };

  const handleCancelOrder = async () => {
    try {
      await updateOrderStatus({
        variables: {
          orderId: selectedOrder._id,
          status: ORDER_STATUS.CANCELED
        },
        refetchQueries: [{ query: GET_ORDERS }]
      });
      setNotification({
        variables: {
          timeout: 4000,
          message: t('orders.cancelOrder', { orderId: selectedOrder.internalOrderId }),
          type: NOTIFICATION_STATUS.SUCCESS,
          isOpen: true,
        },
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  const tableColumns = [
    {
      title: t('orders.restaurant'),
      field: 'restaurantName',
      columnStyles: classes.orderCustomerTitle,
      sortable: false,
    },
    {
      title: t('orders.number'),
      field: 'orderNumber',
      sortable: true,
    },
    {
      title: t('orders.deliveryDate'),
      field: 'deliveryDate',
      sortable: true,
    },
    {
      title: t('orders.orderedAt'),
      field: 'orderedAt',
      sortable: true,
    },
    {
      title: t('orders.netto'),
      field: 'net',
      sortable: false,
    },
    {
      title: t('orders.gross'),
      field: 'gross',
      sortable: false,
    },
    {
      title: t('orders.paymentStatus'),
      field: 'paymentStatus',
      sortable: false,
    },
    {
      title: t('orders.paymentDate'),
      field: 'deadline',
      sortable: false,
    },
    {
      title: t('orders.paymentType'),
      field: 'paymentType',
      sortable: false,
    },
    {
      title: 'PDF / CSV',
      field: 'packageUnit',
      sortable: false,
    },
    {
      title: t('orders.status'),
      field: 'status',
      sortable: true,
    },
  ];

  const {
    page, rowsPerPage, sortBy, sortOrder
  } = state;

  const handleSearchBar = async (searchPhrase) => {
    if (!showSearchResults) {
      setShowSearchResult(true);
    }
    setState({ ...state, page: 0 });
    try {
      await getOrders({
        variables: {
          searchPhrase,
          pageSize: state.rowsPerPage,
          after: 1,
          sortOrder
        }
      });
    } catch (error) {
      console.error('SEARCH_CUSTOMERS_ERROR', error.message);
    }
  };

  const handleResetSearch = () => {
    setShowSearchResult(false);
    handleSearchBar('');
  };

  const hasError = ordersError || (!ordersLoading && !ordersData);

  return (
    <>
      <TableLayout
        withTableLayoutV2Styles
        title={t('orders.title')}
        columns={tableColumns}
        skeletonType={TABLE_SKELETON_TYPES.ORDERS}
        emptyTableData={hasError || !ordersData?.totalResults}
        emptyTableDataMessage={t('orders.empty orders')}
        tableIsLoading={ordersLoading}
        withActions
        withSearchBar
        headerActionsBeforeSearchBar={isAiOrdersEnabled && (
          <SwitchTablesActionButtons
            isOrdersActive
            isAiOrdersEnabled={isAiOrdersEnabled}
          />
        )}
        searchPlaceholder={t('orders.search placeholder')}
        handleSearchBar={handleSearchBar}
        handleShowSearchResult={handleResetSearch}
        page={page}
        sortBy={sortBy}
        sortOrder={sortOrder}
        handleSortRows={handleSortRows}
        rowsPerPage={rowsPerPage}
        totalResults={ordersData?.totalResults ?? 0}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        // withDetailPanel
      >
        {ordersData?.orders
          .map((rowData) => (
            <OrdersTableRow
              key={rowData._id}
              rowData={rowData}
              cancelOrder={showCancelOrderModal}
              acceptOrder={handleAcceptOrder}
              messageOrder={messageOrder}
              isCanceled={!!(rowData.status === ORDER_STATUS.CANCELED)}
              isPending={!!(rowData.status === ORDER_STATUS.PENDING)}
            />
          ))}
      </TableLayout>
      <CancelOrderModal
        isOpen={isOpenCancelOrderModal}
        onConfirm={handleCancelOrder}
        handleCloseModal={() => setCancelOrderModal(false)}
      />
    </>
  );
}
