import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/styles';
import StandardButton from './Buttons/StandardButton';
import Transition from './Transition';

const useStyles = makeStyles(theme => ({
  modalContainer: {
    maxWidth: '90vw',
    minWidth: '650px',
  },
  modalMessage: {
    padding: theme.spacing(3, 3),
  },
  modalMessageContainer: {
    overflowY: 'unset',
  },
  modalBackdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalTitle: {
    padding: theme.spacing(3, 3),
    textAlign: 'center',
  },
  modalActions: {
    padding: theme.spacing(3, 3),
  },
}));

function ModalDialog({ isOpen, onClose, title, children, actions }) {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.modalContainer }}
      BackdropProps={{ classes: { root: classes.modalBackdrop } }}
      open={isOpen}
      TransitionComponent={Transition}
      aria-labelledby="status-modal"
      aria-describedby="status-modal-description"
      onClose={onClose}
    >
      <DialogTitle className={classes.modalTitle} id="status-modal">
        {title}
      </DialogTitle>
      <DialogContent className={classes.modalMessageContainer}>
        {children}
      </DialogContent>
      <DialogActions className={classes.modalActions}>
        {actions?.map((action, index) => (
          <StandardButton
            key={`${action.label}-${index}`}
            onClick={action.onClick}
          >
            {action.label}
          </StandardButton>
        ))}
      </DialogActions>
    </Dialog>
  );
}

ModalDialog.defaultProps = {
  actions: [],
};

ModalDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
};

export default ModalDialog;
